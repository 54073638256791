

































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import MenuModel from '@/models/MenuModel';
import MenuType from '@/utils/enums/MenuType';
import { Getter, namespace, State } from 'vuex-class';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import UiPage from '@/models/graphql/UiPage';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CommunityUser from '@/models/graphql/CommunityUser';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import RouteHelper from '@/utils/helpers/RouteHelper';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import Community from '@/models/graphql/Community';
import Exhibitor from '@/models/graphql/Exhibitor';
import SubEdition from '@/models/graphql/SubEdition';
import Channel from '@/models/graphql/Channel';
import MenuHelpers from '@/utils/helpers/MenuHelpers';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import MenuItemType from '@/utils/enums/MenuItemType';
import SalesPackage from '@/models/graphql/SalesPackage';
import SalesPackageComponent from '@/models/graphql/SalesPackageComponent';
import { mixins } from 'vue-class-component';
import ChatModuleHelper from '@/components/chat/ChatModuleHelper.vue';
import GroupType from '@/utils/enums/chat/GroupType';
import CompanyUserRolePermission from '@/utils/enums/CompanyUserRolePermission';

const feedItemWrapperStore = namespace('FeedItemWrapperStore');
const companyCalendarStore = namespace('CompanyCalendarStore');
const salesPackageLimitStore = namespace('SalesPackageLimitStore');
const salesPackageStore = namespace('SalesPackageStore');
const exhibitorStore = namespace('ExhibitorStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    ButtonIconComponent,
    ButtonComponent,
    AvatarSoloWidget,
    FontAwesomeComponent,
  },
  beforeRouteEnter(to, from, next) {
    if (to.path === '/change-email') {
      next({
        name: 'change-email',
        query: to.query,
      });
      return;
    }
    next((vm) => {
      const that = vm as SideMenu;
      if (that.isUnifyExhibitorPortal) {
        if (that.companyRoles.length > 0) {
          if (that.$route.params?.companyId) {
            that.selectedCompanyId = that.$route.params.companyId;
            that.selectedCompanyRole = that.companyRoles.find((cr) => cr.company?.uid === that.selectedCompanyId);
          }

          if (!that.selectedCompanyRole) {
            const foundDefaultCompany = that.companyRoles.find((c) => !!c.defaultCompany);
            if (foundDefaultCompany) {
              that.selectedCompanyId = foundDefaultCompany.company?.uid;
              that.selectedCompanyRole = foundDefaultCompany;
            } else {
              that.selectedCompanyId = that.companyRoles[0].company?.uid;
              // eslint-disable-next-line prefer-destructuring
              that.selectedCompanyRole = that.companyRoles[0];
            }
            that.$router.push({
              path: `/${that.selectedCompanyId}`,
              replace: true,
            });
          }
          if (that.selectedCompanyRole?.handleMeetings) {
            that.setCountForCompanyMeetingRequest();
          }
          if (that.selectedCompanyRole?.canPost) {
            that.setFeedInitiator(that.selectedCompanyRole.company as Exhibitor);
          }
        } else {
          that.$router.push({ name: 'forbidden' });
        }
      }
    });
  },
})
export default class SideMenu extends mixins(BreakpointWrapper, ChatModuleHelper) {
  @Getter
  private readonly menuByType!: (menuType: MenuType) => MenuModel[];

  @Getter
  private readonly pages!: UiPage[];

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private readonly community!: Community;

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  @State
  private readonly unifyDomain!: string;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @salesPackageLimitStore.Action
  private filterSlots!: (payload: { exhibitorUid: string; code: string })
    => Promise<{ type: string; limit: number; code: number }[]>;

  @salesPackageStore.Action('getPackages')
  private getPackages!: (payload: { filter: object; exhibitorId: string })
    => Promise<SalesPackage[] | undefined>;

  @permissionManagerStore.Getter
  private canHandleCompanyMeeting!: (companyUid: string) => boolean;

  @companyCalendarStore.Action
  private loadUnreadMeetingRequestCount!: (filter: object) => Promise<number | undefined>;

  @feedItemWrapperStore.Mutation
  private setFeedInitiator!: (initiator: CommunityUser | Exhibitor | SubEdition | Channel) => void;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  private FileResourceHelper = FileResourceHelper;

  private isOpen = false;

  private rootMenuRoute: string | null = null;

  private isDropped: string | null = null;

  private selectedCompanyId: string | undefined | null = null;

  private selectedCompanyRole: CompanyUserRole | undefined | null = null;

  private renderMenu = 0;

  private renderSideMenu = 0;

  private salesComponentsIds: number[] = [];

  private mandatorySalesPackages: SalesPackage[] = [];

  private companyPackages: SalesPackage[] = [];

  private firstUnReadMessageCount = true;

  private get unReadMessageCount(): number {
    return this.getter<number>('unReadCount') || 0;
  }

  private get logoPath(): string {
    if (this.community?.logoFileResource?.path) {
      return FileResourceHelper.getFullPath(this.community?.logoFileResource, 'w256');
    }
    return this.community?.logoFileResource?.path || '';
  }

  private get mainMenuList(): MenuModel[] {
    return this.menuByType(MenuType.MAIN);
  }

  private get companyRoles(): CompanyUserRole[] {
    if (this.authUser?.companyRoles) {
      const companies = this.authUser.companyRoles;
      return companies.reduce((acc: CompanyUserRole[], cur: CompanyUserRole) => {
        if (acc.findIndex((cmp) => cmp.company?.uid === cur.company?.uid) === -1) {
          acc.push(cur);
        }
        return acc;
      }, []);
    }
    return [];
  }

  private get currentCompanyRole(): CompanyUserRole | null {
    if (this.companyRoles && this.companyRoles.length > 0) {
      const companyRole = this.companyRoles.find((role) => role.company?.uid === this.adminPanelExhibitor.uid);
      return companyRole || null;
    }
    return null;
  }

  private get companyProfileLink(): string {
    if (this.unifyDomain && this.selectedCompanyId) {
      return RouteHelper.externalNavigationBetweenPortals(
        this.unifyDomain,
        'company-detail',
        { companyId: this.selectedCompanyId },
      );
    }
    return this.$router.resolve({ name: 'not-found' }).href;
  }

  private get unifyPortalUrl(): string {
    return RouteHelper.externalNavigationBetweenPortals(this.unifyDomain, 'home');
  }

  private get userCanManageSales(): boolean {
    const companyRole = this.authUser.companyRoles
      .find((role) => role.company?.uid === this.adminPanelExhibitor.uid);
    return companyRole?.manageSales || false;
  }

  created(): void {
    this.removeStoreOnDestroy = false;
    this.storeContext = 'ExhibitorPortalMessageWidgetStore';
    this.$eventsBus.on('toggle-side-menu', (): void => {
      this.setSideMenuWidth(!this.isOpen);
    });
    this.$eventsBus.on('on-update-company-meeting-request-count', this.setCountForCompanyMeetingRequest);
  }

  mounted(): void {
    this.setRootMenuRoute();
    this.setSideMenuWidth(this.isUnifyExhibitorPortal && this.isDesktop);
    const currentActiveMenu = this.mainMenuList
      .find((menu) => menu.subMenu && menu.subMenu.length > 0
        && menu.subMenu.findIndex((subMenu) => this.$route.meta && subMenu.targetPage === this.$route.meta.pageId) > -1);
    if (currentActiveMenu) {
      this.isDropped = currentActiveMenu.uid;
    }
  }

  beforeDestroy(): void {
    this.setSideMenuWidth(false);
  }

  private canSeeMenuItem(menu: MenuModel): boolean {
    if (this.userHavePermission(menu)) {
      return menu.salesComponent
        ? this.companyHasComponent(menu.salesComponent) || this.menuHasPackage(menu.salesComponent)
        : true;
    }
    return false;
  }

  private userHavePermission(menu: MenuModel): boolean {
    if (menu.userPermissionNeeded) {
      switch (menu.userPermissionNeeded) {
        case CompanyUserRolePermission.MANAGE_COMPANY_USER_ROLES:
          return this.currentCompanyRole ? this.currentCompanyRole.manageCompanyUserRoles || false : false;
        case CompanyUserRolePermission.MODERATOR:
          return this.currentCompanyRole ? this.currentCompanyRole.moderator || false : false;
        case CompanyUserRolePermission.CAN_POST:
          return this.currentCompanyRole ? this.currentCompanyRole.canPost || false : false;
        case CompanyUserRolePermission.MANAGE_SALES:
          return this.currentCompanyRole ? this.currentCompanyRole.manageSales || false : false;
        case CompanyUserRolePermission.HANDLE_MEETINGS:
          return this.currentCompanyRole ? this.currentCompanyRole.handleMeetings || false : false;
        case CompanyUserRolePermission.MANAGE_CONTENT:
          return this.currentCompanyRole ? this.currentCompanyRole.manageContent || false : false;
        default:
          return true;
      }
    }
    return true;
  }

  @Watch('adminPanelExhibitor', { deep: true })
  private onExhibitorReady(): void {
    if (this.adminPanelExhibitor) {
      this.menuListReady();
    }
  }

  @Watch('mainMenuList', { deep: true })
  private menuListReady(): void {
    if (this.mainMenuList && this.mainMenuList.length > 0) {
      this.salesComponentsIds = [];
      this.mainMenuList.forEach((menu: MenuModel) => {
        if (menu.subMenu) {
          menu.subMenu.forEach((subMenu: MenuModel) => {
            if (subMenu.salesComponent) {
              this.salesComponentsIds.push(subMenu.salesComponent);
            }
          });
        }
        if (menu.salesComponent) {
          this.salesComponentsIds.push(menu.salesComponent);
        }
      });
    }
    if (this.mainMenuList && this.mainMenuList.length > 0 && this.adminPanelExhibitor) {
      const queryFilter = {
        active: true,
        includedByDefault: false,
        // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/camelcase
        OR: [{ rank_gt: this.adminPanelExhibitor._maxSalesPackageRank }, { rank: null }, { rank: 0 }],
        parentSalesPackage: null,
        salesPackageComponent: {
          salesComponent: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            id_in: this.salesComponentsIds,
            schemaCode: this.community.code,
          },
        },
      };
      this.getPackages({
        filter: queryFilter,
        exhibitorId: this.adminPanelExhibitor.uid,
      })
        .then((response: SalesPackage[] | undefined) => {
          this.mandatorySalesPackages = response || [];
        });
    }
  }

  private menuHasPackage(salesComponentId: number): boolean {
    return !!this.mandatorySalesPackages
      .find((salesPackage: SalesPackage) => !!(!salesPackage.includedByDefault ? salesPackage.salesPackageComponent
        ?.find((salesPackageComponent: SalesPackageComponent) => salesPackageComponent.salesComponent?.id
          && salesPackageComponent.salesComponent?.id === salesComponentId) : false));
  }

  private companyHasComponent(salesComponentId: number): boolean {
    const packageExist = this.menuHasPackage(salesComponentId);
    if (packageExist) {
      return !!this.companyPackages
        .find((salesPackage: SalesPackage) => salesPackage.salesPackageComponent
          ?.find((salesPackageComponent: SalesPackageComponent) => salesPackageComponent.salesComponent?.id
            && salesPackageComponent.salesComponent?.id === salesComponentId));
    }
    return false;
  }

  @Watch('adminPanelExhibitor', { deep: true })
  private loadExhibitorPackages(): void {
    if (this.adminPanelExhibitor && this.adminPanelExhibitor.uid) {
      const queryFilter = {
        active: true,
        AND: [
          // eslint-disable-next-line @typescript-eslint/camelcase
          { OR: [{ _isPurchased_gt: 0 }, { includedByDefault: true }] },
        ],
      };

      this.getPackages({
        filter: queryFilter,
        exhibitorId: this.adminPanelExhibitor.uid,
      })
        .then((response: SalesPackage[] | undefined) => {
          if (response && response.length > 0) {
            this.companyPackages = response;
          }
        });
    }
  }

  private setCountForCompanyMeetingRequest(): void {
    this.calculateParentMenuItemCount();
    const meetingRequestMenuItem = MenuHelpers.findMenuItemByType(this.mainMenuList, MenuItemType.COMPANY_MEETING_REQUEST);
    if (meetingRequestMenuItem && this.$route.params?.companyId) {
      this.canIHandleMeetingRequest()
        .then((doIHavePermission) => {
          if (doIHavePermission) {
            this.loadUnreadMeetingRequestCount({
              exhibitor: {
                uid: this.$route.params.companyId,
              },
              readState: false,
              // eslint-disable-next-line @typescript-eslint/camelcase
              trash_not: true,
              meeting: null,
            })
              .then((unreadCount) => {
                meetingRequestMenuItem.menuItemCount = unreadCount || 0;
                this.calculateParentMenuItemCount();
              });
          } else {
            meetingRequestMenuItem.menuItemCount = 0;
            this.calculateParentMenuItemCount();
          }
        });
    }
  }

  @Watch('unReadMessageCount', { immediate: true })
  private setCountForCompanyMessages(): void {
    this.calculateParentMenuItemCount();
    const messageMenuItem = MenuHelpers.findMenuItemByType(this.mainMenuList, MenuItemType.MESSAGE);
    if (messageMenuItem && this.$route.params?.companyId) {
      if (this.firstUnReadMessageCount) {
        this.$store.dispatch('ChatDispatcherStore/ChatStore/loadUnreadMessageCount', {
          // eslint-disable-next-line @typescript-eslint/camelcase
          type_not: GroupType.QNA,
          users: { uid: this.authUser.uid },
          OR: [
            {
              target: { uid: null },
            },
            {
              target: {
                uid: this.$route.params.companyId,
              },
            },
          ],
          _isUnreadFilter: '',
        })
          .then((unreadCount) => {
            this.firstUnReadMessageCount = false;
            messageMenuItem.menuItemCount = unreadCount || 0;
            this.calculateParentMenuItemCount();
          });
      } else {
        messageMenuItem.menuItemCount = this.unReadMessageCount || 0;
        this.calculateParentMenuItemCount();
      }
    }
  }

  private calculateParentMenuItemCount(): void {
    this.mainMenuList.forEach((item) => {
      if (item.subMenu && item.subMenu.length > 0) {
        item.menuItemCount = item.subMenu.reduce((acc, curr) => acc + (curr.menuItemCount || 0), 0);
      }
    });
    this.renderMenu += 1;
  }

  private async canIHandleMeetingRequest(): Promise<boolean> {
    if (this.selectedCompanyId
      && this.canHandleCompanyMeeting(this.selectedCompanyId)
      && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING)
      && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING)?.enabled) {
      if (this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE)?.enabled) {
        const response = await this.filterSlots({
          exhibitorUid: this.selectedCompanyId,
          code: SlotTypeEnum.MEETING_FEATURE,
        });
        if (response && response[0]) {
          return Promise.resolve(response[0].limit === -1 || response[0].limit > 0);
        }
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  }

  private setRootMenuRoute(): void {
    let i = 0;
    while (this.mainMenuList.length > i && !this.rootMenuRoute) {
      const menu = this.mainMenuList[i];
      if (menu.targetPage) {
        const foundPage = this.pages.find((page) => page.id === menu.targetPage);
        if (foundPage) {
          const route = this.$router.resolve({ name: foundPage.code }).href;
          if (['', '/'].includes(route) && this.selectedCompanyId && this.isUnifyExhibitorPortal) {
            this.rootMenuRoute = menu.uid;
          }
        }
      }
      i += 1;
    }
  }

  private getUrl(menu: MenuModel): string {
    if (menu && menu.targetPage) {
      const foundPage = this.pages.find((page) => page.id === menu.targetPage);
      if (foundPage) {
        const route = this.$router.resolve({ name: foundPage.code }).href;
        if (['', '/'].includes(route) && this.selectedCompanyId && this.isUnifyExhibitorPortal) {
          return `/${this.selectedCompanyId}`;
        }
        return route;
      }
    }
    if (menu.internalUrl) {
      return menu.internalUrl;
    }
    return '';
  }

  private setSideMenuWidth(isOpen: boolean): void {
    const { body } = document;
    if (body) {
      this.isOpen = isOpen;
      body.style.setProperty('--side-menu-width', this.isOpen && this.isDesktop ? '20.313rem' : '0rem');
    }
  }

  private onSelectCompany(companyUserRole: CompanyUserRole): void {
    this.selectedCompanyRole = companyUserRole;
    this.selectedCompanyId = companyUserRole.company?.uid;
    this.commit('clearCache');
    if (this.selectedCompanyRole?.canPost) {
      this.setFeedInitiator(this.selectedCompanyRole.company as Exhibitor);
    }
    this.$router.push({
      name: this.$route.name as string,
      params: { companyId: this.selectedCompanyId as string },
    })
      .finally(() => {
        this.firstUnReadMessageCount = true;
        this.setCountForCompanyMessages();
        this.setCountForCompanyMeetingRequest();
      });
  }

  private dropIt(menu: string | null): void {
    if (this.isDropped) {
      if (this.isDropped === menu) {
        this.isDropped = null;
      } else {
        this.isDropped = menu;
      }
    } else {
      this.isDropped = menu;
    }
  }

  @Watch('isDesktop')
  private updateSideMenuVisibility(): void {
    this.setSideMenuWidth(this.isDesktop);
  }
}
